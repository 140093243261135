import BaseMixin from '@/views/components/officials/mixins/Base';
import Trienniums from '@/services/Api/Trienniums';
import OfficialsPublic from '@/services/Api/Public/Officials';
import Triennium from '@/helpers/triennium';

export default {
  mixins: [BaseMixin],
  props: {
    selector: String,
  },
  data() {
    return {
      triennials: [],
      triennialsFormatted: [],
      trienniumNumber: '',
    };
  },
  async mounted() {
    await this.fetchTriennials();

    // Set initial value from query string
    if (this.$route.query.triennium) {
      const [firstYear] = this.$route.query.triennium.split('-');

      if (firstYear) {
        try {
          const trienniumNumber = Triennium.calculateNumberFromYear(firstYear);
          if (trienniumNumber > 0) {
            this.trienniumNumber = trienniumNumber;
          }
        } catch (e) {
          console.log('error');
        }
      }
    } else {
      this.trienniumNumber = (new Triennium()).number;
    }
  },
  methods: {
    async fetchTriennials() {
      const data = await Trienniums.all();
      this.triennials = data.data.data;

      this.triennials.sort((a, b) => b.from - a.from);

      this.triennialsFormatted = this.triennials.map((triennium) => ({
        value: triennium.number,
        text: `${triennium.from} - ${triennium.to}`,
        slug: `${triennium.from}-${triennium.to}`,
      }));
    },
    async fetchAffiliates() {
      this.isLoading = true;
      const data = await OfficialsPublic.affiliates(
        this.officialsCategory,
        { triennium: this.trienniumNumber },
      );
      this.setAffiliates(data.data.data);
      this.isLoading = false;
    },
  },
  computed: {
    selectedTriennium() {
      return this.triennials.find((t) => t.number === this.trienniumNumber);
    },
    selectedTrienniumFormatted() {
      return this.triennialsFormatted.find((t) => t.value === this.trienniumNumber);
    },
  },
  watch: {
    async trienniumNumber(newValue) {
      const triennium = new Triennium(newValue);

      const query = {
        category: this.$route.query.category,
        triennium: `${triennium.from()}-${triennium.to()}`,
      };

      this.$router
        .push({ path: '/affiliates/officials', query })
        .catch(() => {
          // skip duplicated warning
        });

      await this.fetchAffiliates();
    },
  },
};
