<template>
  <div class="p-4">
    <div>
      <b-card-text>
        List of triennia:
      </b-card-text>
      <b-form-select class="form-control"
                     v-model="trienniumNumber"
                     :options="triennialsFormatted"
                     size="sm">
      </b-form-select>
    </div>
    <div v-if="selectedTriennium">
      <h3 class="mt-4">
        {{ category.title }} in {{ selectedTrienniumText }}
      </h3>

      <b-alert variant="warning" :show="hasIfacRestricted">
        You may login to see the IFAC Restricted names
      </b-alert>

      <b-alert class="mb-0" variant="warning" :show="!isLoading && rowData.length === 0">
        No public IFAC official in this category at this time
      </b-alert>

      <ag-grid-vue
        v-show="rowData.length > 0"
        class="ag-theme-alpine"
        :pagination="true"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :gridOptions="gridOptions"
        domLayout="autoHeight"
        :paginationPageSize="paginationPageSize"
        :getRowStyle="getRowStyle"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import OfficialsWithTriennial from '@/views/components/officials/mixins/AffiliatesWithTriennial';

export default {
  mixins: [OfficialsWithTriennial],
  computed: {
    selectedTrienniumText() {
      if (this.category?.triennial_field === 'end_year') {
        return this.selectedTriennium.to;
      }

      return this.selectedTrienniumFormatted.text;
    },
    hasIfacRestricted() {
      return this.rowData.filter((a) => a.is_name_visible === false).length > 0;
    },
  },
};
</script>
